import "@hotwired/turbo-rails"

import * as bootstrap from 'bootstrap'

//import '@client-side-validations/client-side-validations/src'
//import '@client-side-validations/simple-form/src/index.bootstrap4'

import "@rails/actioncable"
import "@rails/activestorage"
import "trix"
import "@rails/actiontext"



import "../controllers"
import "../components"


import { createIcons, icons } from 'lucide';

// Caution, this will import all the icons and bundle them.
createIcons({ icons });

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import './application.scss'

//import './application.scss'
